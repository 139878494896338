import './style.less';
import { settings } from '../../../../areas/main/config';
import $ from 'jquery';
import ko from 'knockout';
import _ from 'lodash';

function formatResult(item): string {
	if (!item.id) {
		return item.text;
	}

	return `<span class='iconselect-result'><span class='${settings.iconPrefix}${item.id}'></span>${item.text}</span>`;
}

ko.bindingHandlers['iconSelect'] = {
	init: function (element) {
		import (/* webpackChunkName: "select2" */ '../../../../plugins/select2').then(m => {
			$(element).select2({
				allowClear: true,
				formatResult: formatResult,
				escapeMarkup: (m) => m,
				matcher: function (term, text, option) {
					term = term.trim().toLowerCase();
					if (term === '') {
						return true;
					}

					if (text.toLowerCase().indexOf(term) > -1) {
						return true;
					}

					let $option = $(option);
					let filters = $option.attr('data-filter').split(';');

					let val = $option.val();
					if (typeof val === 'string') {
						filters.push(val);
					}

					return _.some(filters, function (filter: string) {
						if (filter.toLowerCase().indexOf(term) > -1) {
							return true;
						}
					});
				},
				sortResults: function (results, container, query) {
					var term = query.term.trim().toLowerCase();
					if (term === '') {
						return results;
					}

					return results.sort(function (a, b) {
						return sortValue(a.text) - sortValue(b.text);
					});

					function sortValue(text) {
						text = text.toLowerCase();
						if (text === term) {
							return 0;
						}
						else if (text.indexOf(term) > -1) {
							return 1;
						}
						else {
							return 2;
						}
					}
				}
			});
		});
	},
}
