import jQuery from 'jquery';

jQuery($ => {
	$("img.chart").each(
		(_, e) => {
			var $e = $(e);
			var src = decodeURI($e.attr("data-src"));
			var width = $e.parent().innerWidth();
			var height = $e.parent().innerHeight();
			width = Math.floor(Math.min(width, height * 4));
			src = src.replace("{width}", width.toString());
			src = src.replace("{height}", height.toString());
			$e.attr("src", src);
		}
	);
});
