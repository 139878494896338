import $ from 'jquery';
import ko from 'knockout';
import { focusFirstField } from './focus';
import 'bootstrap/js/tab';

let focus: number;

ko.bindingHandlers['formTabFocus'] = {
	init: function (element, valueAccessor, allBindingsAccessor) {
		let e = $(element);
		const tabId: string = valueAccessor();

		let hash = /^#![\w-]+$/.test(window.location.hash) ? window.location.hash.substr(2) : null;
		const tabExists = hash && $(`.form-tabs > li.${hash}`).length == 1;

		if (!tabExists && !$('.form-tabs > li').hasClass('active')) {
			hash = tabId;
		}

		if (hash === tabId) {
			e.tab('show');
			$(`#${tabId} .sub-tabs > ul > li`).first().addClass('default-sub-tab');

			focusTab(tabId);
		}

		//shown event must be bound after setting current tab to prevent problems loading related lists
		//todo: refactor to remove conflict with subTabFocus binding
		e.on('shown.bs.tab', () => {
			//load the 1st sub tab if no other sub tab was previously loaded
			let subTabs = $(`#${tabId} .sub-tabs > ul > li`);
			if (subTabs.length > 0 && !subTabs.hasClass('active')) {
				subTabs.first().find('a').tab('show');
			}

			window.history.replaceState(null, null, `#!${tabId}`);
			focusTab(tabId);
		});
	}
}

export function focusTab(tabId: string, allowMultipleTimes: boolean = true) {
	if (tabId) {
		tabId = '.form.edit-mode #' + tabId;
		focusFirstField($(tabId));
	}
	else {
		tabId = $('.form.edit-mode .form-tabs > li.active > a').attr('href');
	}

	if (focus) {
		clearTimeout(focus);
	}

	focus = window.setTimeout(() => {
		focusFirstField($(tabId), allowMultipleTimes)
	});
}
