import { Field, FileField, List, ListItemForm, NumberField, ObjectSelectField, ScalarField, SelectField, StringField } from '../model';
import $ from 'jquery';
import ko from 'knockout';
import { parseClientModel } from '../../../util/parse';
import { expand } from './expand';
import { settings } from '../../../areas/main/config';
import { initializeGridDropDown } from '../grid/initializeGrid';

declare global {
	module kendo {
		module data {
			interface Model {
				canMove: boolean;
				canReorder: boolean;
				isRemoved?: boolean;
			}
		}
	}

	interface JQuery {
		kendoTaskBoard(options: kendo.ui.TaskBoardOptions): JQuery;
	}
}

interface TaskBoardModel {
	titleField: string;
	columnStatusField: string;
	orderField: string;
	statusField: string;
	columns: TaskBoardColumn[];
	cardFields: any[];
	canEdit: boolean;
	canAdd: boolean;
}

interface TaskBoardColumn {
	id: string;
	title: string;
	status?: string;
	color?: string;
}

interface TaskBoardItem {
	id: string;
	title: any;
	order: number;
	status: string;
	canMove: boolean;
	canRemove: boolean;
	canReorder: boolean;
	isValid: boolean;
	color?: string;
	isRemoved?: boolean;
	hasChanges?: boolean;
	showContextEditButton?: boolean;
	viewUrl?: string;
	editUrl?: string;

	values?: {[index: string]: any;};
	cssClass?: () => string;
}

interface CardFieldValue {
	value: string;
	label: string;
	linkUrl?: string;
	linkTarget?: string;
	infoUrl?: string;
	imageUrl?: string;
	isRemoved?: boolean;
}

ko.bindingHandlers['task-board'] = {
	init(element: HTMLElement, _valueAccessor, _allBindingsAccessor, viewModel: List, bindingContext) {
		import(/* webpackChunkName: "kendo-scheduler" */ '../../../plugins/kendo/scheduler').then(_ => {
			const $element = $(element);
			const model = <TaskBoardModel>parseClientModel($('.task-board-model', $element.parent()));
			const forms: { [index: string]: ListItemForm; } = {};

			const taskBoard = $element.kendoTaskBoard({
				columnSettings: {
					template: kendo.template($('#column-template').html()),
					buttons: model.canAdd ? [{ name: 'CustomButton', text: settings.strings.addItem, icon: 'plus', command: 'addNew'}] : []
				},
				columns: {
					data: model.columns
				},
				height: '100%',
				dataOrderField: 'order',
				toolbar: false,
				dataSource: buildDataSource(),
				template: kendo.template($('#card-template').html()),
				dataBound: () => setColumnCount(),
				change: (e) => change(e),
				editCard: (e) => editCard(e),
				moveStart: (e) => moveStart(e),
				move: (e) => move(e),
				moveEnd: (e) => moveEnd(e)
			}).data('kendoTaskBoard');

			viewModel.items.subscribe(_ => taskBoard.dataSource.read());

			function change(e: kendo.ui.TaskBoardChangeEvent) : void {
				if (e.action === 'receive') {
					const statusField = getCardField<SelectField>(e.card.id, model.columnStatusField);
					statusField.parse(e.column.id);
				}

				if (e.action === 'receive' || e.action === 'remove') {
					setColumnCount();

					if (model.orderField) {
						const items = taskBoard.itemsByColumn(e.columnElement);
						for (let index = 0; index < items.length; index++) {
							const item = taskBoard.dataItem($(items[index])) as any;
							const orderField = getCardField<ScalarField>(item.id, model.orderField);
							if (!orderField.isDisabled()) {
								orderField.parse(index);
							}
						}
					}
				}

				function getCardField<T>(id: string, name: string): T {
					return getField<T>(forms[id], name)
				}
			}

			function editCard(e: kendo.ui.TaskBoardEditCardEvent): void {
				forms[e.card.id].contextEdit();
				e.preventDefault();
			}

			function moveStart(e: kendo.ui.TaskBoardMoveStartEvent): void {
				if (!e.card.canMove || e.card.isRemoved) {
					e.preventDefault();
				}
				else if (!e.card.canReorder) {
					$element.addClass('reorder-is-disabled');
					$(e.cardElement).addClass('card-is-moving');
				}
			}

			function move(e: kendo.ui.TaskBoardMoveEvent): void {
				if (!e.card.canReorder) {
					$('.k-taskboard-column').removeClass('is-drop-target');
					$(e.columnElement).addClass('is-drop-target');
				}
			}

			function moveEnd(e: kendo.ui.TaskBoardMoveEndEvent): void {
				if (!e.card.canMove || e.card.isRemoved) {
					e.preventDefault();
				}
				else if (!e.card.canReorder && e.action === 'receive') {
					e.preventDefault();

					$(e.cardElement).removeClass('card-is-moving');
					$element
						.removeClass('reorder-is-disabled')
						.find('.k-taskboard-column')
						.removeClass('is-drop-target');

					const currentStatus = (e.card as any).status;
					const newStatus = (e.column as any).status;

					if (currentStatus != newStatus) {
						const lastCardUid = $(e.columnElement).find('.k-taskboard-card').last().attr('data-uid');
						const task = taskBoard.dataSource.get(e.card.id);
						task.status = newStatus;
						task.order = lastCardUid ? taskBoard.dataSource.getByUid(lastCardUid).order + 1 : 0;

						const statusField = getField<SelectField>(forms[e.card.id], model.columnStatusField);
						statusField.parse(newStatus === settings.undefinedString ? null : newStatus);
					}
				}
			}

			function setColumnCount(): void {
				if (!taskBoard) {
					return;
				}

				for (let i = 0; i < taskBoard.columns().length; i++) {
					const column = $(taskBoard.columns()[i]);
					const count = taskBoard.itemsByColumn(column).length;
		
					column.find('.column-count').text(count);
				}
			}

			function getField<T>(form: ListItemForm, name: string): T {
				return form.fields.map[name] as T;
			}

			function formatValue(field: ScalarField, value: CardFieldValue, form: ListItemForm) {
				if (!field.isNull()) {
					const canExpand = !(field instanceof StringField) || field.form.dataId() > 0;
					if (canExpand) {
						value.linkUrl = expand(field.linkUrl, field);
						value.linkTarget = field.linkTarget;
					}

					if (field instanceof SelectField) {
						value.infoUrl = field.buildInfoUrl();
					}
					else if (!form.parentKey) {
						//info urls are only shown for the display name of root elements as child elements
						//could have a different type
						value.infoUrl = expand(field.infoUrl, field);
					}

					if (field instanceof FileField) {
						value.imageUrl = expand(field.imageUrl, field);
					}
				}

				return value;
			}

			function buildDataSource(): any {
				return {
					transport: {
						read: (operation) => {
							const items = viewModel.items().map((x: ListItemForm) => {
								const title = getField<ScalarField>(x, model.titleField);
								const statusField = getField<SelectField>(x, model.statusField);
								const orderField = getField<NumberField>(x, model.orderField);
								const columnStatusField = getField<SelectField>(x, model.columnStatusField);
								const canMove = () => x.editMode && !x.isRemoved() && !columnStatusField.isDisabled();

								const item: TaskBoardItem = {
									id: x.key ?? Math.random().toString(16),
									title: title.displayValue() || x.displayName,
									status: getStatusValue(columnStatusField.value()),
									//fallback to the list position but put new items on top
									order: model.orderField ? orderField.value() : (x.state() === 'added' ? -x.position() : x.position()),
									color: statusField ? statusField.statusColorClass() ?? 'default' : null,
									canReorder: model.orderField && !orderField.isDisabled() || false,
									canRemove: x.canRemove,
									isValid: x.isValid(),
									canMove: canMove(),
									cssClass: () => {
										const classes = [];

										if (x.state() == 'added') {
											classes.push('is-new');
										}
										else if (x.hasChanges()) {
											classes.push('has-changes');
										}

										if (x.isRemoved()) {
											classes.push('is-removed');
										}

										if (!x.isValid()) {
											classes.push('has-errors');
										}

										if (x.editMode) {
											if (x.showContextEditButton) {
												classes.push('can-edit');
											}
	
											if (canMove()) {
												classes.push('can-move');
											}
											else {
												classes.push('is-locked');
											}
										}

										return classes.join(' ');
									}
								};

								if (model.cardFields.length > 0) {
									item.values = {};

									model.cardFields.forEach(c => {
										const field = getField<Field>(x, c.field);
										if (field instanceof ScalarField) {
											if (field.isVisible()) {
												const value: CardFieldValue = {
													label: field.label,
													value: field.displayValue(),
													isRemoved: item.isRemoved
												};

												item.values[field.id] = formatValue(field, value, x);

												field.displayValue.subscribe(v => sync(item.id, (i: TaskBoardItem) => {
													i.values[field.id].value = v;
													i.values[field.id] = formatValue(field, i.values[field.id], x);
												}));
											}
											else {
												item.values[field.id] = '';
											}
										}
									});
								}

								if (x.showViewButton) {
									item.viewUrl = x.url;
								}

								if (x.showEditButton) {
									item.editUrl = x.editUrl;
								}
	
								if (x.showContextEditButton) {
									item.showContextEditButton = true;
								}

								columnStatusField?.value.subscribe(v => sync(item.id, (i) => i.status = getStatusValue(v)));
								title.displayValue.subscribe(v => sync(item.id, (i) => i.title = v));
								statusField?.value.subscribe(v => sync(item.id, (i) => i.color = statusField.statusColorClass()));
								orderField?.value.subscribe(v => sync(item.id, (i) => i.order = v));
								x.isRemoved.subscribe(v => sync(item.id, (i) => i.isRemoved = v));
								x.isValid.subscribe(v => sync(item.id, (i) => i.isValid = v));
								x.hasChanges.subscribe(v => sync(item.id, (i) => i.hasChanges = v));
	
								forms[item.id] = x;

								function getStatusValue(value: any) {
									return (columnStatusField instanceof ObjectSelectField ? value?.id : value) ?? settings.undefinedString;
								}
	
								function sync(id: string, callback: (item: TaskBoardItem) => void) {
									const task = taskBoard.dataSource.get(id) as any;
									
									//ensure task is marked as dirty so it will update
									task.dirty = true;

									callback(task);

									taskBoard.dataSource.sync();
								}

								return item;
							});

							operation.success(items);
						},
						update: (operation) => {
							operation.success(operation.data);
						}
					}
				}
			}

			function runEvent(e: JQuery.ClickEvent, callback: (form: ListItemForm) => void): any {
				const row = $(e.currentTarget).closest('.k-taskboard-card');
				const task = taskBoard.dataItem(row) as any;
				const form = forms[task.id];

				callback(form);

				task.isRemoved = form.isRemoved();

				e.preventDefault();
			}

			$element
				.on('click', 'button.remove', e => runEvent(e, (form) => form.remove()))
				.on('click', 'button.restore', e => runEvent(e, (form) => form.restore()))
				.on('click', 'button.quick-edit', e => runEvent(e, (form) => form.contextEdit()));

			kendo.ui.taskboard.commands['addNew'] = kendo.ui.taskboard.Command.extend({
				exec: function () {
					const status = this.options.column.get('status');
					viewModel.push((form) => {
						if (status && status != settings.undefinedString) {
							const statusField = getField<SelectField>(form, model.columnStatusField);
							if (!statusField.isDisabled()) {
								statusField.parse(status);
							}
						}
					});
				}
			});

			//prevent the the column buttons from doing a page reload
			$element.find('.column-buttons button').on('click', (e) => e.preventDefault());

			initializeGridDropDown($element);

			ko.applyBindingsToDescendants(bindingContext, element);

			return { controlsDescendantBindings: true };
		});
	}
}
