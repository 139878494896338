import jQuery from 'jquery';
import ko from 'knockout';
import { SelectField } from '../model';

ko.bindingHandlers['fieldOptions'] = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel: SelectField) {
		var e = $(element);
		var value: any = ko.utils.unwrapObservable(valueAccessor());
		var url = value.url || (value.urlAttribute && e.attr(value.urlAttribute));
		if (url) {
			jQuery
				.get(url, {
					i: viewModel.form.dataId() || 0,
					n: 1000
				})
				.done((result, status, xhr) => {
					viewModel.options(result);
				});
		}
	}
}
