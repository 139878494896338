import ko from 'knockout';

ko.bindingHandlers['fileset'] = {
	init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		let value = valueAccessor();

		import(/* webpackChunkName: "fileset" */ "../fileset/FilesetEditor").then(m => {
			const FilesetEditor = m.default;

			let filesetEditor = new FilesetEditor(viewModel, value);

			filesetEditor.flow.assignDrop(element);

			let childBindingContext = bindingContext.createChildContext(
				filesetEditor.fileset,
				null,
				context => {
					ko.utils.extend(context, valueAccessor());
				}
			);

			ko.applyBindingsToDescendants(childBindingContext, element);
		});

		return { controlsDescendantBindings: true };
	}
}
