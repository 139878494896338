import $ from 'jquery';
import ko from 'knockout';
import _ from 'lodash';
import { ScalarField } from '../model';

ko.bindingHandlers['flags'] = {
	init: function (element: HTMLInputElement, valueAccessor, _allBindingsAccessor, _viewModel, bindingContext) {
		let value = ko.unwrap(valueAccessor());
		let field: ScalarField =  bindingContext.$parent;

		element.checked = (value & field.value()) > 0;

		$(element).on("change", () => {
			if (element.checked) {
				field.value(field.value() | value);
			}
			else {
				let result = field.value() & ~value
				field.value(result || null);
			}
		})
	},
	update: function (element: HTMLInputElement, valueAccessor, _allBindingsAccessor, _viewModel, bindingContext) {
		let value = ko.unwrap(valueAccessor());
		let field: ScalarField =  bindingContext.$parent;

		element.checked = (value & field.value()) > 0;
	}
}
