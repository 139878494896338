import $ from 'jquery';
import ko from 'knockout';
import { Field, ScalarField } from '../model';

ko.bindingHandlers['expand'] = {
	init: function (element, valueAccessor) {
		var e = $(element);
		var attr = ko.utils.unwrapObservable<string>(valueAccessor());
		var template = e.attr(attr) || e.attr('data-' + attr) || '';
		e.data('expand-' + attr, template);
	},
	update: function (element, valueAccessor, _, viewModel: Field) {
		var e = $(element);
		var attr = ko.utils.unwrapObservable<string>(valueAccessor());
		var template: string = e.data('expand-' + attr);

		const value  = expand(template, viewModel);
		e.attr(attr, value);
	}
}

export function expand(template: string, field:Field): string {
	if (!template) {
		return template;
	}

	const id = (field.form && field.form.dataId() + '') || field.id;
	const pattern = /\{(id|p|val|formid)\}/gi;

	return template.replace(pattern, (_, p1: string) => {
		switch (p1.toLowerCase()) {
			case "id":
			case "p":
				return id;

			case "formid":
				return (field.form && field.form.formId) || id;

			case "val":
				return field instanceof ScalarField ? field.format() : id;
		}
	});
}
