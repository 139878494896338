import jQuery from 'jquery';
import { settings } from '../../areas/main/config';
import { handle as handleReflow } from '../../areas/main/events/reflow';
import { format } from '../../util/format';
import { parseClientModel } from '../../util/parse';
import xmlEncode from '../../util/xmlEncode';
import { onLoad } from '../form/load';

declare global {
	interface JQuery {
		highcharts(options?: Highcharts.Options);
	}
}

export default async function initialize() {
	await initializeChart();

	onLoad(async (container) => {
		await initializeChart(container);
	})
}

async function initializeChart(container?: JQuery) {
	const charts = jQuery('.report-chart', container).toArray().map(e => {
		let container = jQuery('.chart-container', e)
		var data: any = parseClientModel(jQuery('.chart-model', e)) || {};

		var links: Record<string, string> = data.categoryLinks || {};
		let options: Highcharts.Options = data.highchart || {};

		let isPopulatedArray = (x: unknown) => Array.isArray(x) && x.length > 0;
		let hasData = isPopulatedArray(options.series) && options.series.some((x: any) => isPopulatedArray(x.data));

		return {
			container,
			links,
			options,
			hasData,
			requires3d: hasData && options?.chart?.options3d?.enabled === true,
			requiresCylinder: hasData && options?.chart?.type === 'cylinder'
		};
	});

	if (charts.length > 0) {
		const Highcharts = await import('highcharts');

		if (charts.some(x => x.requires3d)) {
			const Highcharts3d = await import('highcharts/highcharts-3d');
			Highcharts3d.default(Highcharts);
		}

		if (charts.some(x => x.requiresCylinder)) {
			const Cylinder = await import('highcharts/modules/cylinder');
			Cylinder.default(Highcharts);
		}

		Highcharts.setOptions({
			colors: [
				"#77aadd",
				"#ee8866",
				"#44bb99",
				"#99ddff",
				"#bbcc33",
				"#aaaa00",
				"#eedd88",
				"#ffaabb",
				"#dddddd"
			],
			chart: {
				animation: {
					duration: 40,
					easing: ''
				}
			},
			plotOptions: {
				series: {
					animation: false,
					states: {
						inactive: {
							opacity: 1
						},
						hover: {
							halo: {
								opacity: 0.5
							}
						}
					},
				},
				pie: {
					dataLabels: {
						formatter: function () {
							return this.point.name || settings.strings.nullText
						},
						style: {
							fontWeight: 'normal'
						}
					},
					depth: 35
				}
			},
			lang: {
				decimalPoint: settings.currentCulture.numberDecimalSeparator,
				thousandsSep: settings.currentCulture.numberGroupSeparator
			}
		});

		for (const { container, hasData, links, options } of charts) {
			if (hasData) {
				if (Object.keys(links).length) {
					if (!Array.isArray(options.xAxis)) {
						options.xAxis.labels.formatter = function () {
							var url = links[this.value];
							if (!url) {
								return xmlEncode(this.value.toString());
							}

							return format('<a href="{0}">{1}</a>', url, xmlEncode(this.value.toString()));
						};
					}

					options.plotOptions.series.cursor = 'pointer';
					options.plotOptions.series.events = {
						click: e => {
							var url = links[e.point.category || e.point.name];
							if (url) {
								location.href = url;
							}
						}
					};
				}

				const chartInstance = container.highcharts(options);

				handleReflow(() => {
					setTimeout(() => chartInstance.highcharts().reflow(), 100);
				});
			}
			else {
				container.html(`
					<div class="no-data">
						<span class="fa fa-area-chart"></span>
						<h3>${settings.strings.noData}</h3>
					</div>
				`);
			}
		};
	}
}
