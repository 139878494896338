import jQuery from 'jquery';

const id = 'konfig:reflow'

export function raise() {
	jQuery(window).trigger(id);
}

export function handle(handler: { (): void }) {
	jQuery(window).on(id, handler);
}
