import ko from 'knockout';
import Message from './Message';
import notify from '../notify';
import { format } from '../../util/format';
import { HubConnection, HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import { settings } from '../../areas/main/config';

export default class MessageBox {
	items = ko.observableArray<Message>([]);
	serverUnreadCount = ko.observable<number>(0);
	serverUnacknowledgedCount = ko.observable<number>(0);

	unacknowledged = ko.pureComputed(
		() => this.items().filter(x => x.acknowledgedOn() === null).sort((a, b) => b.id - a.id)
	);

	unread = ko.pureComputed(
		() => this.items().filter(x => !x.isRead()).sort((a, b) => b.id - a.id)
	);

	read = ko.pureComputed(
		() => this.items().filter(x => x.isRead()).sort((a, b) => b.id - a.id)
	);

	hasItems = ko.pureComputed(() => this.items().length > 0);

	unacknowledgedCount = ko.pureComputed(() => this.serverUnacknowledgedCount() + this.unacknowledged().length);

	hasUnacknowledged = ko.pureComputed(() => this.unacknowledgedCount() > 0);

	unreadCount = ko.pureComputed(() => this.serverUnreadCount() + this.unread().length);

	hasUnread = ko.pureComputed(() => this.unreadCount() > 0);

	hasRead = ko.pureComputed(() => this.read().length > 0);

	unacknowledgedCountLabel = ko.pureComputed(() => {
		const badgeLimit = 99;

		const unacknowledgedCount = this.unacknowledgedCount();

		if (unacknowledgedCount > badgeLimit) {
			return `${badgeLimit}+`;
		}

		return unacknowledgedCount;
	});

	markAllAsReadLabel = ko.pureComputed(() => {
		return format(settings.strings.markAllAsRead, this.unreadCount());
	});

	private connection: HubConnection;

	constructor() {
		this.connect();
	}

	acknowledgeNew = async (model: MessageBox) => {
		if (this.hasUnacknowledged()) {
			const latestId = Math.max(...model.items().map(x => x.id));

			await this.connection.send("acknowledgeNew", latestId);
			this.markAllAcknowledged(latestId);
			this.serverUnacknowledgedCount(0);
		}
	}

	markAsRead = async (message: Message) => {
		if (message) {
			await this.connection.send("markAsRead", message.id);
			this.markRead(message);
		}
	}

	markAllAsRead = async (model: MessageBox, e: JQuery.Event) => {
		if (this.hasUnread()) {
			const latestId = Math.max(...model.items().map(x => x.id));

			await this.connection.send("markAllAsRead", latestId);
			this.markAllRead(latestId);
			this.serverUnreadCount(0);
		}

		e.stopImmediatePropagation();
	}

	start = async () => {
		await this.connection
			.start()
			.catch(console.error);
	}

	private connect() {
		const box = this;

		this.connection = new HubConnectionBuilder()
			.withUrl("/signalr/hubs", {
				skipNegotiation: true,
				transport: HttpTransportType.WebSockets
			})
			.build();

		this.connection.on("add", (message: any) => {
			box.recieveMessage(message, true);
		});

		this.connection.on("initialize", (messages: any[], unacknowledgedCount: number, unreadCount: number) => {
			for (let m of messages) {
				box.recieveMessage(m, false);
			}

			box.serverUnacknowledgedCount(unacknowledgedCount - box.unacknowledged().length);
			box.serverUnreadCount(unreadCount - box.unread().length);
		});

		this.connection.on("acknowledgeNew", (latestMessageId: number) => {
			box.markAllAcknowledged(latestMessageId);
			this.serverUnacknowledgedCount(0);
		});

		this.connection.on("markAsRead", (id: number) => {
			box.markRead(box.findMessage(id));
		});

		this.connection.on("markAllAsRead", (latestMessageId: number) => {
			box.markAllRead(latestMessageId);
			this.serverUnreadCount(0);
		});
	}

	private findMessage(id: number) {
		const [result] = this.items().filter(x => x.id === id);
		return result;
	}

	private recieveMessage(data: any, showNotification: boolean) {
		const message = new Message(data, this);

		if (this.findMessage(message.id)) {
			return;
		}

		this.items.unshift(message);

		if (showNotification && this.items().indexOf(message) === 0) {
			notify(message.body, message.subject, message.link, message.id);
		}
	}

	private markAllAcknowledged(latestMessageId: number) {
		const timestamp = new Date();

		for (const m of this.unacknowledged().filter(x => x.id <= latestMessageId)) {
			m.acknowledgedOn(timestamp);
		}
	}

	private markAllRead(latestMessageId: number) {
		for (const m of this.unread().filter(x => x.id <= latestMessageId)) {
			m.isRead(true);
		}
	}

	private markRead(message: Message) {
		if (message) {
			message.isRead(true);
		}
	}
}
