import jQuery from 'jquery';
import ko from 'knockout';

ko.bindingHandlers["ajax-request-verification"] = {
	init: function (element: HTMLElement) {
		const tokenId = "__RequestVerificationToken";
		const token = jQuery(`input[name="${tokenId}"]`, element).val();

		if (token) {
			jQuery(document).bind("ajaxSend", (_, __, settings: JQueryAjaxSettings) => {
				const contentType = settings.contentType + "";
				if (settings.type === "POST" && contentType.startsWith("application/x-www-form-urlencoded")) {
					settings.data = settings.data || "";
					if (typeof settings.data === "string" && !settings.data.includes(tokenId)) {
						settings.data += `&${tokenId}=${encodeURIComponent(<string>token)}`;
					}
				}
			});
		}
	}
}
