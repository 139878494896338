import './style.less';
import initializeChart from './chart';
import initializePivot from './pivot';
import initializeTelerik from './telerik';

export default async function initialize() {
	await initializeChart();
	await initializePivot();
	await initializeTelerik();
}
