import ko, { Observable } from 'knockout';
import { settings } from '../../../areas/main/config';
import { format } from '../../../util/format';

interface Options {
	dataType?: string;
	callback: (value: string) => void;
	isActive: Observable<boolean>;
}

ko.bindingHandlers['paste-receiver'] = {
	init: function (element: HTMLElement, valueAccessor) {
		const options: Options = valueAccessor();
		const focusElement = element.querySelector<HTMLInputElement>('.focus-element');
		const messageElement = element.querySelector<HTMLElement>('.message');

		messageElement.innerHTML = format(settings.strings.pasteRowsPrompt, pasteKeyShortcut());

		element.addEventListener('paste', e => {
			const transfer = e.clipboardData;
			const data = transfer.getData(options.dataType || 'text/plain');

			options.callback(data);
			options.isActive(false);
		});

		focusElement.addEventListener(
			'blur', () => options.isActive(false)
		);

		options.isActive.subscribe(isActive => {
			if (isActive) {
				element.classList.add('active');
				focusElement.focus();
			}
			else {
				element.classList.remove('active');
			}
		});
	}
}

function pasteKeyShortcut() {
	const isMac = /macintosh/i.test(navigator.userAgent);
	const modifierKey = isMac ? 'Cmd' : 'Ctrl';

	return `<kbd>${modifierKey}</kbd><kbd>V</kbd>`
}
