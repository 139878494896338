import * as fn from '../../util/fn';
import codeEditor from '../../components/code-editor';
import initializeDashboard from './dashboard';
import initializeDialog from '../../components/dialog';
import initializeForm from '../../components/form';
import initializeMenu from './menu';
import initializeMessaging from '../../components/messaging';
import initializeReport from '../../components/report';
import initializeShortcuts from './keyboard/shortcuts';
import jQuery from 'jquery';
import ko from 'knockout';
import { format, formatYesNo } from '../../util/format';
import { getRootForm } from '../../components/form/model';
import { loadConfig, settings } from './config';
import { raise as raiseReady } from '../main/events/ready';
import './fragments/error-detail';
import './fragments/image-chart';
import './fragments/show-hidden';
import './fragments/top-menu';
import './images';
import './style/index.less';

//these exports support templating and cross frame communication
export interface KonfigExports {
	format: typeof format;
	formatYesNo: typeof formatYesNo;
	strings: typeof settings.strings;
	minimumPasswordLength: typeof settings.minimumPasswordLength;
	getRootForm: typeof getRootForm;
	codeEditor: typeof codeEditor;
}

jQuery(async function app() {
	ko.options.deferUpdates = true;

	jQuery.expr[":"].containsi = jQuery.expr.createPseudo(function (arg: string) {
		return function (elem: HTMLElement) {
			return jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
		};
	});

	jQuery.ajaxSetup({
		statusCode: {
			401: function () {
				location.reload();
			}
		}
	});

	await loadConfig(
		jQuery("#konfig-config").html()
	);

	let exports: KonfigExports = {
		format: format,
		formatYesNo: formatYesNo,
		strings: settings.strings,
		minimumPasswordLength: settings.minimumPasswordLength,
		getRootForm: getRootForm,
		codeEditor: codeEditor
	};

	window['konfig'] = exports;
	window['fn'] = fn;

	initializeForm();
	initializeDashboard();
	await initializeReport();
	initializeDialog();
	initializeMessaging();
	initializeMenu();
	initializeShortcuts();

	raiseReady();
});
