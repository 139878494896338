import $ from 'jquery';
import ko from 'knockout';
import { ListItemForm } from '../model';

ko.bindingHandlers['selectListItem'] = {
	init: function (element: HTMLInputElement, valueAccessor, allBindingsAccessor, viewModel: ListItemForm) {
		$(element).change(e => {
			var selected = ko.unwrap(valueAccessor());
			var select = !selected;
			viewModel.list().selectItem(viewModel, select, e.shiftKey);
			e.preventDefault();
		});
	},
	update: function (element: HTMLInputElement, valueAccessor, allBindingsAccessor, viewModel: ListItemForm) {
		element.checked = ko.unwrap(valueAccessor());
	}
}
