import ko from 'knockout';
import { IFilterable } from '../../../../components/form/model';
import { parseClientModel } from '../../../../util/parse';
import { handle as handleQueryChanged, paramsWhichDiffer } from '../../events/queryChanged';

export function initialize() {
	jQuery('.k-panel.list, .k-panel.report-query').each((_, panel) => {
		const parameterBindingData = jQuery('script.parameter-binding', panel);

		if (parameterBindingData.length === 1) {
			const parameterBindings = parseClientModel(parameterBindingData) as Record<string, string> | null;

			if (parameterBindings) {
				handleQueryChanged(args => {
					const model = ko.dataFor<IFilterable>(jQuery('form.form-model', panel)[0]);
					const differences = paramsWhichDiffer(args);
					let differencesWereApplied = false;

					for (const [filter, param] of Object.entries(parameterBindings)) {
						if (differences.has(param)) {
							model.setFilterValue(filter, args.current.get(param));
							differencesWereApplied = true;
						}
					}

					if (differencesWereApplied) {
						model.applyFilter();
					}
				});
			}
		}
	});
}
