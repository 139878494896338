import ko from 'knockout';
import { Field } from '../model';

ko.bindingHandlers['subTabFocus'] = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel: Field) {
		let e = $(element);

		e.on('shown.bs.tab', () => {
			viewModel.focus();
		});

		if (e.parent().hasClass('default-sub-tab')) {
			e.tab('show');
		}

		let event = valueAccessor();

		if (!viewModel[event]) {
			viewModel[event] = () => {
				e.tab('show');
			}
		}
	}
}
