import { PopoverOptions } from 'bootstrap';
import 'bootstrap/js/popover';
import $ from 'jquery';
import ko from 'knockout';
import { error as showError } from '../../notify';

ko.bindingHandlers['flashMessage'] = {
	update: function (element, valueAccessor) {
		var message = ko.utils.unwrapObservable<string>(valueAccessor());
		var $element = $(element);

		//knockout runs all updates for an element even if our observable hasn't changed,
		//so we have to do change tracking ourselves
		var lastMessage = $element.data('flashMessage');
		if (message == lastMessage) {
			return;
		}

		function destroy() {
			var $pop = $element.data('flashPopover');
			if ($pop) $pop.popover('destroy');

			$element.data({
				'flashMessage': null,
				'flashPopover': null
			});
		}

		//either we have a new message, or we're clearing the old one
		destroy();

		if (message) {
			if ($element.is(":hidden")) {
				showError(message);
				return;
			}

			var options: PopoverOptions = {
				trigger: 'manual',
				container: 'body',
				placement: function (popover, source) {
					//In order to get the actual width of the popover it has to be attached to the body
					//otherwise it will return a width of 0
					var $popover = $(popover);
					$("body").append($popover);
					var popoverWidth = $popover.width();
					$popover.remove();

					var availableSpaceToTheRight = $(window).width() - ($(source).offset().left + $(source).width());

					if (availableSpaceToTheRight > popoverWidth) {
						return "right";
					}

					return "left";
				}
			};

			var lines = message.split('\n');
			if (lines.length > 1) {
				options.title = lines[0];
				options.content = lines.slice(1).join('\n');
			}
			else {
				options.content = message;
			}

			var $pop = $element.popover(options);
			$pop.popover('show');

			$element.data({
				'flashMessage': message,
				'flashPopover': $pop
			});

			setTimeout(destroy, 5000);
		}
	}
}
