import jQuery from 'jquery';

interface IFlyoutOptions {
	header: JQuery;
	list: JQuery;
	style?: FlyoutStyle;
	disposed?: () => void;
}

export enum FlyoutStyle {
	menu = 'flyout-menu',
	drawer = 'flyout-drawer'
};

export default class Flyout {
	static init(menu: JQuery) {
		const hideDelayMs = 150;
		let hideTimer: number;

		menu.on('mouseout', _ => {
			const isSearching = document.activeElement && document.activeElement.classList.contains('menu-search');

			if (!isSearching) {
				hideTimer = window.setTimeout(Flyout.hide, hideDelayMs);
			}
		});

		menu.on('mouseover mousemove', _ => {
			clearTimeout(hideTimer);
		});

		menu.find('section.items').on('scroll', Flyout.hide);
	}

	static hide() {
		Flyout.show(null);
	}

	static show(options: IFlyoutOptions | null) {
		const active = Flyout.active;

		if (active) {
			if (options && active.represents(options)) {
				return;
			}
			else {
				active.dispose();
			}
		}

		if (options) {
			new Flyout(options);
		}
	}

	static update() {
		Flyout.active && Flyout.active.layout();
	}

	private constructor(options: IFlyoutOptions) {
		Flyout.active = this;

		options.style = options.style || FlyoutStyle.menu;

		options.header.addClass(`flyout ${options.style} flyout-header`);
		options.list.addClass(`flyout ${options.style} flyout-list `);

		this.options = options;
		this.layout();
	}

	private represents(options: IFlyoutOptions) {
		const [ours] = this.options.list.toArray();
		const [theirs] = options.list.toArray();

		return ours === theirs;
	}

	public layout() {
		const { list, header, style } = this.options;

		//remove any layout to get the resting dimensions, but capture the
		//list's scroll position first, so it can be restored later on
		const listScroll = list.scrollTop();
		this.reset();

		const topEdge = jQuery('#top-bar').height();
		const headerBounds = header[0].getBoundingClientRect();

		function layoutMenu() {
			const listBounds = list[0].getBoundingClientRect();

			const height = headerBounds.height + listBounds.height;
			const bottom = listBounds.bottom;

			const bottomEdge = jQuery(window).height();
			const maxHeight = bottomEdge - topEdge;

			const crossesBottom = bottom > bottomEdge;
			const crossesTop = height > maxHeight ? true : headerBounds.top < topEdge;

			header.css({
				top: crossesTop ? topEdge : '',
				bottom: crossesTop ? '' : crossesBottom ? listBounds.height : ''
			})

			list.css({
				top: crossesTop ? topEdge + headerBounds.height : '',
				bottom: crossesBottom ? 0 : ''
			});
		}

		function layoutDrawer() {
			header.css({
				top: topEdge,
				bottom: ''
			});

			list.css({
				top: topEdge + headerBounds.height,
				bottom: 0
			});
		}

		if (style === FlyoutStyle.drawer) {
			layoutDrawer();
		}
		else {
			layoutMenu();
		}

		list.scrollTop(listScroll);
	};

	private reset() {
		const css = { top: '', bottom: '' };
		const { list, header } = this.options;

		list.css(css);
		header.css(css);
	}

	private dispose() {
		this.reset();

		const { header, list, disposed } = this.options;

		header.removeClass(`flyout ${this.options.style} flyout-header`);
		list.removeClass(`flyout ${this.options.style} flyout-list`);

		if (disposed) {
			disposed();
		}

		Flyout.active = null;
	}

	private static active: Flyout;

	private options: IFlyoutOptions;
}
