import { showModalPage, showModalPageKey } from './modal';
import './style.less';
import $ from 'jquery';
import { findPopup } from './popup';
import { parseClientModel } from '../../util/parse';

export default function initialize() {
	$(document).on("click", "a[target='popup']", e => {
		var a = $(e.currentTarget);
		var url = a.attr("href");
		showModalPage({
			url: url,
			title: a.attr("data-title"),
			callback: (value, context) => a.focus()
		});
		e.preventDefault();
	});

	if (window.self === window.top) {
		window.self[showModalPageKey] = showModalPage;
	}

	let argument = $('#popup-callback');
	if (argument.length) {
		let p = findPopup(window.self);

		if (!p) {
			return;
		}

		p.callbackArgument = parseClientModel(argument) || null;
		p.close()
	}
};
