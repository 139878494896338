import ko, { Computed } from 'knockout';
import { format } from '../../../util/format';
import { ObjectForm, ScalarField, ValidationItem } from '../model';
import { settings as mainSettings } from '../../../areas/main/config';
import './style.less';

type OptionData = { value: unknown, text: string, color?: number }

type OptionPredicate = (item: OptionEditorItem) => boolean;
type OptionProjection<T> = (item: OptionEditorItem) => T;

export class OptionEditorItem {
	value = ko.observable<unknown>();
	text = ko.observable<string>();
	color = ko.observable<number>();

	label: Computed<string>;

	constructor(item: OptionData) {
		this.value(item.value);
		this.text(item.text);
		this.color(item.color ?? null);

		this.label = ko.computed(() => {
			const color = this.color();
			const colorText = color ? `:${color}` : '';

			return `[${this.value()}${colorText}] ${this.text()}`;
		});
	}

	toJSON = () => {
		return ({
			value: this.value(),
			text: this.text(),
			color: this.color()
		} as OptionData);
	}
}

export default class OptionEditor {
	options = ko.observableArray<OptionEditorItem>();

	selectedValue = ko.observable<unknown>();
	selectedOption: Computed<OptionEditorItem>;
	selectedIndex: Computed<number>;
	hasSelection: Computed<boolean>;

	canMoveUp: Computed<boolean>;
	canMoveDown: Computed<boolean>;
	canModify: Computed<boolean>;

	valueField: ScalarField;
	textField: ScalarField;
	colorField: ScalarField;

	editPanel: ObjectForm;
	editMode = ko.observable(false);
	editValue: Computed<unknown>;
	editValueIsConflicted: Computed<boolean>;
	editText: Computed<string>;
	editTextIsConflicted: Computed<boolean>;
	editColor: Computed<number>;

	isListFocussed = ko.observable<boolean>(true);
	validationMessages: Computed<ValidationItem[]>;

	isDisabled: ko.Observable<boolean>;
	initialValue: unknown;

	onEdit: (form: ObjectForm) => void;

	constructor(data: any, requireUniqueText: boolean) {
		if (data.options) {
			this.parseOptions(data.options);
		}

		const tryFindOption = (predicate: OptionPredicate): OptionEditorItem | undefined => {
			for (const option of this.options()) {
				if (predicate(option)) {
					return option;
				}
			}

			return undefined;
		}

		const optionExists = (predicate: OptionPredicate) => tryFindOption(predicate) !== undefined;

		this.selectedOption = ko.computed(() => tryFindOption(x => x.value() === this.selectedValue()));
		this.selectedIndex = ko.computed(() => this.options.indexOf(this.selectedOption()));
		this.hasSelection = ko.computed(() => this.selectedIndex() >= 0);

		this.isDisabled = ko.observable(data.isDisabled);
		this.canMoveUp = ko.computed(() => !this.isDisabled() && !this.editMode() && this.selectedIndex() > 0);
		this.canMoveDown = ko.computed(() => !this.isDisabled() && !this.editMode() && this.hasSelection() && this.selectedIndex() < this.options().length - 1);
		this.canModify = ko.computed(() => !this.isDisabled() && !this.editMode() && this.hasSelection());

		this.editPanel = new ObjectForm(data.optionEditor);

		const isEditConflicted = <T>(current: OptionProjection<T>, proposed: T) => {
			if (this.editMode()) {
				const isInConflict = () => optionExists(x => current(x) == proposed);

				if (this.hasSelection()) {
					//we are editing an existing item, this is a bit tricky. We
					//only want to check for duplicates if the specific field has
					//actually changed (you may only be editing the text, and
					//leaving the value alone) - otherwise it will be considered
					//a duplicate of itself.
					const hasChanged = current(this.selectedOption()) != proposed;
					return hasChanged ? isInConflict() : false;
				}
				else {
					return isInConflict();
				}
			}

			return false;
		}

		this.textField = <ScalarField>this.editPanel.fields.map['Text'];
		this.valueField = <ScalarField>this.editPanel.fields.map['Value'];
		this.colorField = <ScalarField>this.editPanel.fields.map['Color'];

		this.editValue = ko.computed({
			read: () => this.valueField.value(),
			write: (value) => this.valueField.parse(value)
		});

		this.editValueIsConflicted = ko.computed(() => isEditConflicted(x => x.value(), this.editValue()));

		this.editText = ko.computed({
			read: () => this.textField.value(),
			write: (value) => this.textField.parse(value)
		});

		this.editTextIsConflicted = ko.computed(() => requireUniqueText && isEditConflicted(x => x.text(), this.editText()));

		this.editColor = ko.computed({
			read: () => this.colorField ? this.colorField.value() : null,
			write: (value) =>  this.colorField && this.colorField.parse(value)
		});

		this.validationMessages = ko.computed(() => {
			if (this.editMode()) {
				return [
					...this.editPanel.validationSummary(),
					this.editTextIsConflicted() ? buildUniqueFieldMessage(this.textField.label) : undefined,
					this.editValueIsConflicted() ? buildUniqueFieldMessage(this.valueField.label) : undefined,
				]
				.filter(x => x !== undefined)
			}
			else {
				return [];
			}
		});

		this.initialValue = data.initialValue;

		function buildUniqueFieldMessage(label: string) {
			return { 
				label: label, 
				message: format(mainSettings.strings.uniqueFieldMessage, label)
			} 
		}
	}

	parseOptions(value: string) {
		if (!value) {
			this.options([]);
			return;
		}

		const items: Array<OptionData> = JSON.parse(value);
		const options = items.map(x => new OptionEditorItem(x))

		this.options(options);
	}

	moveUp() {
		if (this.canMoveUp()) {
			const index = this.selectedIndex();
			const option = this.options.splice(index, 1);
			this.options.splice(index - 1, 0, option[0]);
		}
	}

	moveDown() {
		if (this.canMoveDown()) {
			const index = this.selectedIndex();
			const option = this.options.splice(index, 1);
			this.options.splice(index + 1, 0, option[0]);
		}
	}

	remove() {
		this.options.remove(this.selectedOption());
	}

	modify() {
		const option: OptionEditorItem = this.selectedOption();
		if (option) {
			this.edit(option.value(), option.text(), option.color());
		}
	}

	add() {
		function parseIntExact(value: unknown) {
			const match = /^\d+$/.exec('' + value);
			return match ? parseInt(match[0]) : NaN
		}

		const options = this.options();
		const isEmpty = options.length === 0;

		const max = isEmpty
			? NaN
			: options
				.map(x => parseIntExact(x.value()))
				.reduce((a, b) => Math.max(a, b));

		const nextValue = isNaN(max)
			? (isEmpty ? this.initialValue : '')
			: max + 1

		this.selectedValue(undefined);
		this.edit(nextValue, '', null);
	}

	edit(value: unknown, text: string, color?: number) {
		this.editValue(value);
		this.editText(text);
		this.editColor(color);

		this.editMode(true);
		this.onEdit && this.onEdit(this.editPanel);

		this.editPanel.validationSummary([]);
		setTimeout(() => this.editPanel.fields.user[0].focus());
	}

	validate() {
		if (this.editPanel.validate()) {
			return !this.editValueIsConflicted() && !this.editTextIsConflicted();
		}
		else {
			return false;
		}
	}

	accept() {
		if (this.validate()) {
			const option = new OptionEditorItem({
				value: this.editValue(),
				text: this.editText(),
				color: this.editColor()
			});

			if (this.hasSelection()) {
				this.options.replace(this.selectedOption(), option);
			}
			else {
				this.options.push(option);
			}

			this.selectedValue(option.value());
			this.editMode(false);
			this.isListFocussed(true);
		}
	}

	reject() {
		this.editMode(false);
		this.isListFocussed(true);
	}

	serialize() {
		return JSON.stringify([...this.options()]);
	}
}
