import Flyout, { FlyoutStyle } from './flyout';
import './style/index.less';
import * as fn from '../../../util/fn';
import { format } from '../../../util/format';
import { parseJson } from '../../../util/parse';
import { rebuildUrlQuery } from '../../../util/queryString';
import { settings } from '../config';
import { raise as raiseReflow } from '../events/reflow';
import $ from 'jquery';
import * as Cookie from '../../../util/cookie';

const maxRecent = 5;
const menuSelector = '#main-menu';
const toggleSelector = '#toggle-menu';
const searchInputSelector = '.search > input';
const rootItemSelector = 'ul.level-1 > li';

let menu: JQuery;
let clearLocation: () => void;

export default function initialize() {
	if (!settings.showMenu || !$(toggleSelector).length) {
		return;
	}

	menu = $(menuSelector);

	$(toggleSelector).click(_ => {
		if (menu.hasClass("collapsed")) {
			toggle(true);
		}
		else {
			toggle(false);
		}

		return false;
	});

	if (Cookie.get(settings.cookieNames.menuIsExpanded) == '1') {
		toggle(true);
	}
	else {
		toggle(false);
	}

	menu.find('li a').on('click', e => {
		let self = $(e.currentTarget);
		let li = self.closest('li');

		if (self.hasClass('link')) {
			//clear activated item on the top menu
			Cookie.remove(settings.cookieNames.topMenuActiveId);

			let path = self.attr('data-path');
			Cookie.set(settings.cookieNames.menuActiveExpand, path, { path: settings.applicationPath });

			let menuId = parseInt(self.attr('data-id'), 10);
			let appId = parseInt(menu.attr('data-app-id'), 10);

			if (!menuId || !appId) {
				return;
			}

			let menuRecentItems = format(settings.cookieNames.menuRecentItems, appId);
			let recent = parseJson<number[]>(Cookie.get(menuRecentItems)) || [];

			recent = recent.filter(x => x !== menuId);
			recent.unshift(menuId);

			if (recent.length > maxRecent) {
				recent = recent.slice(0, maxRecent);
			}

			Cookie.set(menuRecentItems, JSON.stringify(recent), {
				path: settings.applicationPath,
				expires: fn.today(30)
			});

			return;
		}

		if (menu.hasClass('filtered')) {
			li.toggleClass('filter-expanded');
		}
		else {
			li.toggleClass('expanded');
			Flyout.update();
		}

		e.stopPropagation();
		e.preventDefault();
	});

	initFlyout();
	initSearch();

	if (settings.customizeMode) {
		({ clear: clearLocation } = initLocation());
	}
};

function initSearch() {
	menu.find(searchInputSelector)
		.on("input", e => {
			const term = $(e.currentTarget).val();

			if (typeof term === 'string') {
				applyFilter(term);
			}
		})
		.on('keydown', e => {
			const esc = 27;
			const rtn = 13;

			if (e.which === esc) {
				if ($(e.currentTarget).val() === '') {
					Flyout.hide();
				}
				else {
					clearSearch(true);
				}
			}
			else if (e.which === rtn) {
				let a = $('.filter-hit a:first');
				if (a.length > 0) {
					a[0].click();
				}
			}
		});

	menu.find('.search > .clear').on('click', function () {
		Flyout.hide();
		clearSearch(true);
	});

	menu.find('#toggle-search').on('click', function () {
		if (menu.is('.collapsed')) {
			Flyout.show({
				header: menu.find('.search'),
				list: menu.find('section.items > ul'),
				style: FlyoutStyle.drawer,
				disposed: () => clearSearch()
			});

			clearSearch(true);
		}
	});
}

function initFlyout() {
	menu.on('mouseover click', `${rootItemSelector} > .item .icon`, e => {
		if (menu.is('.collapsed')) {
			const item = $(e.currentTarget).closest('li');

			if (item.closest('.flyout').length > 0) {
				//we're already in a flyout..
				return;
			}

			const list = item.find("> ul");

			if (list.length > 0) {
				item.addClass('active-flyout');

				Flyout.show({
					header: item.find("> .item > .item-label"),
					list: list,
					disposed: () => item.removeClass('active-flyout')
				});
			}
			else {
				Flyout.hide();

				if (e.type === 'click') {
					const [link] = item.find('a.link').toArray();
					link && link.click();
				}
			}
		}
	});

	Flyout.init(menu);
}

function initLocation() {
	menu.addClass('with-location');
	const options = menu.find('.location a');

	function setLocation(value: string) {
		options
			.removeClass('active')
			.filter(`[data-value='${value}']`)
			.addClass('active');

		menu.find(`${rootItemSelector}:not(.recent)`).each((_, e) => {
			const item = $(e);
			item.toggleClass('location-hidden', item.attr('data-location') !== value);
		});

		clearSearch();

		//fill in the location for new roots, and set the type to
		//folder where a specific location is specified
		mapHref(
			menu.find('aside.customize.create-menu a.add'),
			href => rebuildUrlQuery(href, {
				'Type': value ? '1' : undefined,
				'Location': value ? value : undefined
			})
		);

		Cookie.set(
			settings.cookieNames.menuLocation,
			value,
			{ path: settings.applicationPath, expires: fn.today(30) }
		);
	}

	options.on('click', function (e) {
		setLocation($(e.target).attr('data-value'));
		e.preventDefault();
	});

	setLocation(Cookie.get(settings.cookieNames.menuLocation) || '');

	return {
		clear: () => setLocation('')
	};
}

function applyFilter(term: string) {
	const roots = menu.find(`${rootItemSelector}:not(.location-hidden)`);

	menu.removeClass('filtered without-matches');
	menu.find("section.items li").removeClass('filter-visible filter-expanded filter-hit');

	if (term) {
		menu.addClass('filtered');

		const termToken = term.replace(/"/g, '');
		const query = `.item-label.link:containsi("${termToken}")`;

		const matches = roots
			.find(query).addBack(query)
			.closest('li').addClass('filter-visible filter-hit');

		matches
			.parents("li").addClass('filter-visible filter-expanded');

		menu.toggleClass("without-matches", matches.length === 0);
	}
}

function clearSearch(setFocus?: boolean) {
	const input = menu.find(searchInputSelector);

	input.val('');
	input.trigger('input');

	if (setFocus) {
		input.focus();
	}
}

function toggle(value: boolean) {
	clearLocation && clearLocation();
	clearSearch(false);
	Flyout.hide();

	$(menuSelector).toggleClass('collapsed', !value);
	$(toggleSelector).toggleClass("active", value);

	Cookie.set(
		settings.cookieNames.menuIsExpanded,
		value ? '1' : '0',
		{ path: settings.applicationPath, expires: fn.today(30) }
	);

	raiseReflow();
}

function mapHref(element: JQuery, f: (value: string) => string) {
	const href = element.attr('href');
	element.attr('href', f(href));
}
