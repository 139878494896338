import $ from 'jquery';
import ko, { BindingHandler } from 'knockout';

export default function buildKeyHandler(keycode: number) : BindingHandler {
	return ({
		init: function (element, valueAccessor, _, viewModel) {
			$(element).on('keydown', function (event) {
				if (event.which === keycode) {
					const triggerChange = () => {
						ko.utils.triggerEvent(event.target, "change");
						ko.tasks.runEarly();
					}
	
					const value = valueAccessor();
	
					if (typeof value === 'function') {
						triggerChange();
						value.call(viewModel);
					}
					else {
						var selector = ko.utils.unwrapObservable<string>(value);
						if (selector) {
							triggerChange();
							$(selector, element).trigger('click');
						}
					}
	
					return false;
				}
	
				return true;
			});
		}
	});
}
