import appUrl from '../../util/appUrl';
import jQuery from 'jquery';
import ko from 'knockout';
import Messagebox from './MessageBox';
import { timeSince } from '../../util/format';
import './style.less';

const refreshIntervalMs = 60 * 1000;
const faviconVersion = "v1";

export default async function initialize() {
	function refreshTimeSince() {
		jQuery('.messages .message-creation').each(function () {
			const date = new Date(jQuery(this).attr('data-date'));

			jQuery(this).html(timeSince(date));
		});
	}

	const topbar = document.getElementById('top-bar');

	if (topbar) {
		const model = new Messagebox();

		ko.applyBindingsToDescendants(
			{ messagebox: model },
			topbar
		);

		const faviconUrl = appUrl('~/favicon.ico');
		const faviconNoticeUrl = appUrl('~/favicon-notice.ico');

		model.hasUnacknowledged.subscribe(value => {
			let url = `${(value ? faviconNoticeUrl : faviconUrl)}?${faviconVersion}`;
			jQuery('#favicon').attr('href', url);
		});

		setInterval(refreshTimeSince, refreshIntervalMs);

		await model.start();
	}
};
