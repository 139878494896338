import ko, { BindingContext } from 'knockout';
import { ScalarField } from '../model';
import { OptionEditorItem } from '../option-editor/OptionEditor';
import { bindOptionEditor } from './optionEditor';

ko.bindingHandlers['listFilterMapEditor'] = {
	init: function (element, valueAccessor, _, viewModel: ScalarField, bindingContext: BindingContext) {
		let editor = bindOptionEditor(element, valueAccessor, viewModel, bindingContext, false);

		const targetListId = viewModel.form.fields.map["TargetListId"]

		targetListId.value.subscribe(value => {
			editor.editMode(false);
			editor.parseOptions(null);
		});

		editor.onEdit = (form) => {
			const editValue = editor.editValue() as string;

			const excluded = editor.options()
				.map((o: OptionEditorItem) => o.value() as string)
				.filter(x => x !== editValue);

			form.fields.map["TargetListId"].value(targetListId.value());
			form.fields.map["ExcludedFilters"].value(excluded);
		}

		return { controlsDescendantBindings: true };
	}
}
