import ko from 'knockout';
import { ListField } from '../model';

ko.bindingHandlers['attachment'] = {
	init(element, valueAccessor, allBindingsAccessor, viewModel: ListField, bindingContext) {
		let value = valueAccessor();

		import(/* webpackChunkName: "attachment" */ "../attachment/AttachmentEditor").then(m => {
			const AttachmentEditor = m.default;

			let attachmentEditor = new AttachmentEditor(viewModel, value);

			attachmentEditor.flow.assignDrop(element);

			let childBindingContext = bindingContext.createChildContext(
				attachmentEditor,
				null,
				context => {
					ko.utils.extend(context, valueAccessor());
				}
			);

			ko.applyBindingsToDescendants(childBindingContext, element);
		});

		return { controlsDescendantBindings: true };
	}
}
