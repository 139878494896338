import Mousetrap from 'mousetrap';
import { findFramePopup, getCurrentPopup } from '../../../components/dialog/popup';
import { focusFirstField } from '../../../components/form/binding-handlers/focus';
import { getRootForm, ObjectForm } from '../../../components/form/model';

export default function initialize() {
	Mousetrap.prototype.stopCallback = function (e, element, combo) {
		if (getCurrentPopup() != findFramePopup(window.self)) {
			return true;
		}

		let stopCallback: boolean;

		if (combo.startsWith('alt') || combo.startsWith('ctrl')) {
			stopCallback = false;
		}
		else {
			stopCallback =
				element.tagName == 'INPUT'
				|| element.tagName == 'SELECT'
				|| element.tagName == 'TEXTAREA'
				|| (element.contentEditable && element.contentEditable == 'true');
		}

		return stopCallback;
	}

	bindKey('?', () => $('.keyboard-shortcuts').click());

	bindKey(['alt+1', 's'], () => {
		if (!tryClick($('#toggle-search:visible'))) {
			$('.menu-search').focus();
		}

		return false;
	});

	bindKey('alt+2', () => focusFirstField($('#top-bar')));
	bindKey('alt+3', () => focusFirstField($('#main-content')));
	bindKey('esc', () => tryClosePopup());

	bindKey('ctrl+alt+esc', () => {
		closestOrFirstGrid().find('.clear-filter').click();
		return false;
	});

	bindKey('alt+,', () => closestOrFirstGrid().find('.pagination .active').prev().find('a:first').click());
	bindKey('alt+.', () => closestOrFirstGrid().find('.pagination .active').next().find('a:first').click());
	bindKey('ctrl+shift+t', () => focusFirstField(closestOrFirstGrid().find('table:first tbody')));

	bindKey('e', () => tryClick(closestOrFirst('.form-context').find('.btn.edit, btn.context-edit')));
	bindKey('v', () => tryClick(closestOrFirst('.form-context').find('.btn.view')));

	bindKey('ctrl+alt+x', () => {
		let cancel = closestOrFirst('.form-context').find('.form-cancel:visible');

		if (tryClick(cancel)) {
			return;
		}

		tryClosePopup();
	});

	bindKey('alt+j', () => clickTab($('.form-tabs .active').prev()));
	bindKey('alt+k', () => clickTab($('.form-tabs .active').next()));
	bindKey('alt+shift+j', () => clickTab($('.sub-tabs .active').prev()));
	bindKey('alt+shift+k', () => clickTab($('.sub-tabs .active').next()));

	bindKey('ctrl+shift+up', () => focusFieldset(
		closest('.fieldset-row')
			.prevAll('.fieldset-row')
			.find('fieldset:visible')
			.not('.hide-content')
			.last()
	));

	bindKey('ctrl+shift+down', () => focusFieldset(
		closest('.fieldset-row')
			.nextAll('.fieldset-row')
			.find('fieldset:visible')
			.not('.hide-content')
			.first()
	));

	bindKey('ctrl+alt+down', () => closest('.field-context').find('.btn.search').click());
	bindKey('ctrl+alt+a', () => {
		let context = closest('.field-context');

		if (!context.length) {
			context = closestOrFirst('.form-context');
		}

		context.find('.btn.assistant').first().click()
	});

	bindKey(['alt++', 'alt+='], () => {
		let button = closest('.field-context').find('.btn.add:visible');

		if (!button.length) {
			button = closestOrFirstGrid().find('.grid-buttons .btn.add');
		}

		tryClick(button);
	});
}

function bindKey(keys: string | string[], callback: () => void) {
	Mousetrap.bind(keys, callback);
}

function focusFieldset(fieldset: JQuery<HTMLElement>): boolean {
	if (fieldset.length === 0) {
		fieldset = $('.tab-content .active fieldset:visible').not('hide-content').first();
	}

	focusFirstField(fieldset);

	return false;
}

function closest(selector: string): JQuery<Element> {
	return $(document.activeElement).closest(selector);
}

function closestOrFirst(selector: string): JQuery<Element> {
	let e = closest(selector);

	if (e.length) {
		return e;
	}

	return $(selector);
}

function closestOrFirstGrid(): JQuery<Element> {
	let e = closest('.grid');
	if (e.length) {
		return e;
	}

	e = $('.tab-pane .active .grid');
	if (e.length) {
		return e;
	}

	return $('.grid').first();
}

function clickTab(tab: JQuery<HTMLElement>) {
	if (tab.length === 0) {
		return;
	}

	let a = tab.find('a:first');
	a.click().focus();

	let content = tab.closest('.nav-tabs').siblings('.tab-content').find('.tab-pane.active');

	let form = getRootForm();
	if (form instanceof ObjectForm) {
		form.whenIdle(() => {
			focusFirstField(content);
		})
	}
	else {
		focusFirstField(content);
	}
}

function tryClick(element: JQuery<HTMLElement>): boolean {
	if (element.length) {
		//this is necessary as for some reason the jQuery .click() method is not performing any action in some cases
		element[0].click();
		return true;
	}

	return false;
}

function tryClosePopup(): boolean {
	let popup = findFramePopup(window.self);

	if (popup) {
		popup.close();
		return true;
	}

	return false;
}
