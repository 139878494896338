import $ from 'jquery';
import ko from 'knockout';
import { parseClientModel } from '../../util/parse';
import { ReportForm } from '../report/ReportForm';
import { ContextForm, ContextListForm, Form, ListForm, ObjectForm, ParameterForm } from './model';

export function bindForm(container?: Element | JQuery): ObjectForm {
	var element = selectFormElement(container);
	var objectForm: ObjectForm;

	element.each((_, e) => {
		var data: any = parseClientModel($('#form-model', e)) || {};
		var formType = $(e).attr('data-form-type');
		var form: Form;

		switch (formType) {
			case 'list':
				form = new ListForm(data);
				break;

			case 'context':
				form = new ContextForm(data);
				break;

			case 'context-list':
				form = new ContextListForm(data);
				break;

			case 'report':
				form = new ReportForm(data.parameters, data.items)
				break;

			case 'parameter-form':
				form = new ParameterForm(data);
				break;

			default:
				form = objectForm = new ObjectForm(data);
		}
		Form.root = form;
		ko.applyBindings(form, e);
	});

	$('.tip', container).tooltip();

	return objectForm;
}

export function unbindForm(container?: Element | JQuery) {
	var element = selectFormElement(container);
	element.each((i, e) => {
		ko.cleanNode(e);
	});
}

export function findForm(container?: Element | JQuery) {
	var element = selectFormElement(container);
	if (!element || !element.length) {
		return null;
	}

	var form = ko.dataFor(element[0]);
	if (form instanceof Form) {
		return form;
	}
	else {
		return null;
	}
}

function selectFormElement(container?: Element | JQuery): JQuery {
	return $('form.form-model', container)
}
