import jQuery from 'jquery';

const id = 'konfig:ready'
let isReady = false;

export function raise() {
    if (!isReady) {
        isReady = true;
        jQuery(window).trigger(id);
    }
}

export function handle(handler: { (): void }) {
    if (isReady) {
        handler();
    }
    else {
        jQuery(window).on(id, handler);
    }
}
