import appUrl from '../../util/appUrl';
import ko from 'knockout';
import MessageBox from './MessageBox';
import { parseIso8601Date } from '../../util/parse';
import { settings } from '../../areas/main/config';
import { timeSince } from '../../util/format';

enum Style {
	default,
	primary,
	success,
	warning,
	danger
}

class Action {
	label: string;
	url: string;
	buttonStyle: string;
	target: string;

	constructor(data: any) {
		this.label = data.label;
		this.url = appUrl(data.url);
		this.buttonStyle = 'btn-' + Style[Number(data.style) || 0];
		this.target = data.openInNewWindow ? '_blank' : '_self';
	}
}

export default class Message {
	id: number;
	subject: string;
	body: string;
	icon: string;
	link: string;
	sentOn: Date;
	timeSince: string;
	actions: Action[];

	acknowledgedOn = ko.observable<Date | null>(null);

	isRead = ko.observable<boolean>();

	isAcknowledged = ko.pureComputed(() => this.acknowledgedOn() !== null);

	constructor(data: any, public messagebox: MessageBox) {
		this.id = data.id;
		this.subject = data.subject;
		this.body = data.body;
		this.icon = settings.iconPrefix + (data.icon || 'envelope');
		this.link = data.url && appUrl(`~/Message/Link/${this.id}`);
		this.sentOn = parseIso8601Date(data.sentOn);
		this.timeSince = timeSince(this.sentOn);
		this.actions = data.actions && data.actions.map(x => new Action(x)) || [];

		this.isRead(data.isRead);
		this.acknowledgedOn(parseIso8601Date(data.receivedOn));
	}

	markAsRead = (_: Message, e: JQuery.Event) => {
		this.messagebox.markAsRead(this);
		e.stopImmediatePropagation();
	}

	click = (_: Message, e: JQuery.ClickEvent) => {
		const target = ko.dataFor(e.target);

		if (target instanceof Action) {
			return true;
		}
		else if (this.link && $(e.target).is('.message-body')) {
			return true;
		}

		e.stopImmediatePropagation();
		return false;
	}
}
