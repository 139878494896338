import { DateField, FileField, List, ListItemForm, ScalarField, SelectField, StringField } from "../model";
import $ from 'jquery';
import ko from 'knockout';
import { parseClientModel } from "../../../util/parse";
import { expand } from "./expand";
import { Gantt } from "../grid/gantt";

export interface GanttModel {
	idColumn: string;
	parentIdColumn: string;
	titleColumn: string;
	startColumn: string;
	endColumn: string;
	percentCompleteColumn: string;
	statusField?: string;
	percentCompleteIsInt?: boolean;
	hoursPattern: string;
	columns: any[];	
	maxWidth: number;
	canEdit: boolean;
	canAdd: boolean;
}

declare global {
	module kendo {
		module data {
			interface GanttTask {
				values?: {[index: string]: any;};

				viewUrl?: string;
				editUrl?: string;
				cloneUrl?: string;
				cssClass?: string;
				canRemove?: boolean;
				isRemoved?: boolean;
				showContextEditButton?: boolean;
				statusColor?: string;
				statusText?: string;
			}
		}
	}
}

class GanttValue {
	value: string;
	rawValue: any;
	linkUrl?: string;
	linkTarget?: string;
	infoUrl?: string;
	imageUrl?: string;
	isRemoved?: boolean;
}

interface GanttTaskUpdateModel {
	id: any;
	start?: Date;
	end?: Date;
}

interface GanttTaskRow {
	row: JQuery<any>;
	task: kendo.data.GanttTask;
	form: ListItemForm;
}

export function compare(x: GanttValue, y: GanttValue) {
	function resolveValue(v: GanttValue) {
		let value = v ? v.rawValue : v;

		if (value instanceof Object) {
			if (value.hasOwnProperty('text')) {
				value = value.text;
			}
		}

		return value;
	}

	const a = resolveValue(x);
	const b = resolveValue(y);

	if (a === null && b === null) {
		return 0;
	}
	else if (a === null || a < b) {
		return -1
	}
	else if (b === null || a > b) {
		return 1;
	}
	else {
		return 0;
	}
}

ko.bindingHandlers["gantt"] = {
	init(element: HTMLElement, _valueAccessor, _allBindingsAccessor, viewModel: List, bindingContext) {
		const $element = $(element);
		const $container = $element.parent();
		const model = <GanttModel>parseClientModel($('.gantt-model', $container));
		const template = kendo.template($('#cell-template', $container).html(), { useWithBlock: false });

		let expandable = true;

		const columns = [
			{
				width: "86px",
				expandable: false,
				template: kendo.template($('#actions-template', $container).html(), { useWithBlock: false }),
				attributes: {
					"class": "actions"
				}
			},
			...model.columns.map(c => {
				const field = c.field;
				c.template = (data) => template(data.values[field]);

				if (c.sortable) {
					c.sortable = {
						compare: (x: kendo.data.GanttTask, y: kendo.data.GanttTask) => compare(x.values[field], y.values[field])
					}
				}

				if (!c.hidden) {
					//the first visible column should be expandable
					c.expandable = expandable;
					expandable = false;
				}

				if (c.alignmentClass) {
					c.attributes = {
						"class": c.alignmentClass
					};
				}

				return c;
			})
		];

		async function init() {
			const forms: { [index: string]: ListItemForm; } = {};
			const gantt = new Gantt($element, model);

			const control = await gantt.initAsync({
				dataSource: {
					schema: {
						model: {
							fields: {
								id: { type: "string" },
								parentId: { type: "string", nullable: true }
							}
						}
					},
					transport: {
						read: (operation) => {
							const parents = new Set();

							const items = viewModel.items().map((x: ListItemForm) => {
								if (x.parentKey) {
									parents.add(x.parentKey);
								}

								const title = x.fields.map[model.titleColumn];
								const start = x.fields.map[model.startColumn];
								const end = x.fields.map[model.endColumn];
								const statusField = x.fields.map[model.statusField] as SelectField;
								const percentComplete = model.percentCompleteColumn ? x.fields.map[model.percentCompleteColumn] : null;
								const canMove = () => x.editMode && !start.isDisabled() && !end.isDisabled() && !x.isRemoved();

								const result = new kendo.data.GanttTask({
									//ensure we have an id which is needed to map the forms
									id: x.key ?? Math.random().toString(16),
									parentId: x.parentKey || null,
									title: title.value(),
									start: start.value(),
									end: end.value(),
									statusColor: getStatusColor(),
									statusText: statusField?.displayValue(),
									cloneUrl: x.cloneUrl,
									//only allow root elements to be removed
									canRemove: x.canRemove && !x.parentKey,
									isRemoved: x.isRemoved(),
									canMove: canMove(),
									percentComplete: percentComplete?.value(),
									hasChanges: () => x.hasChanges(),
									cssClass: () => {
										let result = [];

										if (x.hasChanges()) {
											result.push('has-changes');
										}
										
										if (percentComplete?.value() >= 1) {
											result.push('is-complete');
										}

										if (x.isRemoved()) {
											result.push('is-removed');
										}

										if (x.editMode) {
											if (x.showContextEditButton) {
												result.push('can-edit');
											}

											if(!start.isDisabled()) {
												result.push('can-move-start');
											}

											if(!end.isDisabled()) {
												result.push('can-move-end');
											}

											if (canMove()) {
												result.push('can-move');
											}
											else {
												result.push('is-locked');
											}
										}

										return result.join(' ');
									}
								});

								result.values = {};

								model.columns.forEach(c => {
									const field = x.fields.map[c.field];
									if (field instanceof ScalarField) {
										if (field.isVisible()) {
											const value = new GanttValue();

											value.value = field.displayValue();
											value.rawValue = field.value();
											value.isRemoved = result.isRemoved;

											if (!field.isNull()) {
												const canExpand = !(field instanceof StringField) || field.form.dataId() > 0;
												if (canExpand) {
													value.linkUrl = expand(field.linkUrl, field);
													value.linkTarget = field.linkTarget;
												}

												if (field instanceof SelectField) {
													value.infoUrl = field.buildInfoUrl();
												}
												else if (!x.parentKey) {
													//info urls are only shown for the display name of root elements as child elements
													//could have a different type
													value.infoUrl = expand(field.infoUrl, field);
												}

												if (field instanceof FileField) {
													value.imageUrl = expand(field.imageUrl, field);
												}
											}

											result.values[field.id] = value;

											field.value.subscribe(v => sync(() => value.rawValue = v, result));
											field.displayValue.subscribe(v => sync(() => value.value = v, result));
										}
										else {
											result.values[field.id] = "";
										}
									}
								});

								if (x.showViewButton) {
									result.viewUrl = x.url;
								}

								if (x.showEditButton) {
									result.editUrl = x.editUrl;
								}

								if (x.showContextEditButton) {
									result.showContextEditButton = true;
								}

								title.value.subscribe(v => sync(() => result.title = v, result));
								start.value.subscribe(v => sync(() => result.start = v, result));
								end.value.subscribe(v => sync(() => result.end = v, result));
								percentComplete?.value.subscribe(v => sync(() => result.percentComplete = v, result));
								x.isRemoved.subscribe(v => sync(() => result.isRemoved = v, result));

								statusField?.value.subscribe(v => sync(() => {
									result.statusText = statusField.displayValue();
									result.statusColor = getStatusColor();
								}, result));

								forms[result.id] = x;

								function sync(callback: Function, task: kendo.data.GanttTask) {
									callback();

									gantt.syncCssClass(task);

									control.dataSource.sync();
								}

								function getStatusColor() {
									return statusField?.statusColorClass() ?? 'background-default';
								}

								return result;
							});

							for (let n = 0; n < items.length; n++) {
								const task = items[n];

								if (parents.has(task.id)) {
									task.summary = true;
								}
							}

							gantt.ensureDates(items);
							operation.success(items);
						}
					}
				},
				columns: columns,
				add: (e) => add(e),
				edit: (e) => edit(e),
				moveStart: (e) => moveStart(e),
				move: (e) => move(e),
				save: (e) => save(e),
				resize: (e) => move(e),
				resizeEnd: (e) => resizeEnd(e),
				editable: {
					confirmation: false,
					create: model.canAdd,
					dependencyCreate: false,
					dependencyDestroy: false,
					dragPercentComplete: false,
					destroy: false,
					move: model.canEdit || model.canAdd,
					reorder: false,
					resize: model.canEdit || model.canAdd,
					update: model.canEdit || model.canAdd,
					plannedTasks: false
				}
			});

			viewModel.items.subscribe(_ => {
				control.dataSource.read().then(_ => {
					gantt.resize();
					control.refresh();
				});
			});

			$element
				.on('click', 'button.remove', e => {
					const item = getTargetItem(e);

					item.form.remove();
					item.task.isRemoved = item.form.isRemoved();

					if (item.task.isRemoved) {
						item.row.addClass("removed");
					}

					refreshRemoved(item.task);

					e.preventDefault();
				})
				.on('click', 'button.restore', e => {
					const item = getTargetItem(e);

					item.form.restore();
					item.task.isRemoved = false;

					item.row.removeClass("removed");

					refreshRemoved(item.task);

					e.preventDefault();
				})
				.on('click', 'button.quick-edit', e => {
					const item = getTargetItem(e);
					item.form.contextEdit();

					e.preventDefault();
				});

			function getTargetItem(e: JQuery.ClickEvent): GanttTaskRow {
				const row = $(e.currentTarget).closest('tr');
				const task = control.dataItem(row);

				return {
					row: row,
					task: task,
					form: forms[task.id]
				};
			}

			function refreshRemoved(rowModel: kendo.data.GanttTask): void {
				for (const key in rowModel.values) {
					const value = rowModel.values[key];
					if(value instanceof GanttValue) {
						value.isRemoved = rowModel.isRemoved;
					}
				}

				control.refresh();
			}

			function add(e: kendo.ui.GanttAddEvent): void {
				viewModel.push();
				e.preventDefault();
			}

			function edit(e: kendo.ui.GanttEditEvent): void {
				if (!e.task.isRemoved) {
					const form = forms[e.task.id];
					if (form.showContextEditButton) {
						form.contextEdit();
					}
				}

				e.preventDefault();
			}

			function save(e: kendo.ui.GanttSaveEvent): void {
				if (e.task.canMove && !e.task.isRemoved) {
					const model: GanttTaskUpdateModel = {
						id: e.task.id,
						start: e.values.start,
						end: e.values.end
					};

					update(model, e.task);
				}

				e.preventDefault();
			}

			function moveStart(e: kendo.ui.GanttMoveStartEvent): void {
				if (e.task.isRemoved || !e.task.canMove) {
					e.preventDefault();
				}
			}

			function move(e: kendo.ui.GanttResizeEvent | kendo.ui.GanttMoveEvent): void {
				if (e.task && !e.task.isRemoved) {
					const fields = forms[e.task.id].fields.map;
					const start = fields[model.startColumn] as DateField;
					const end = fields[model.endColumn] as DateField;

					e.start = roundDate(e.start, start.formatString());
					e.end = roundDate(e.end, end.formatString());
				}
			}

			function resizeEnd(e: kendo.ui.GanttResizeEndEvent): void {
				if (!e.task.isRemoved) {
					const fields = forms[e.task.id].fields.map;

					if (e.start !== e.task.start && !fields[model.startColumn].isDisabled()) {
						const model: GanttTaskUpdateModel = {
							id: e.task.id,
							start: e.start
						};

						update(model, e.task);
					}
					else if(e.end !== e.task.end && !fields[model.endColumn].isDisabled()) {
						const model: GanttTaskUpdateModel = {
							id: e.task.id,
							end: e.end
						};

						update(model, e.task);
					}
				}

				e.preventDefault();
			}

			function update(model: GanttTaskUpdateModel, task: kendo.data.GanttTask): void {
				const fields = forms[model.id].fields.map;
				const start = fields[gantt.model.startColumn] as DateField;
				const end = fields[gantt.model.endColumn] as DateField;
				const isMilestone = task.start.getTime() === task.end.getTime();

				let format: string = null;

				if (isMilestone) {
					//we need to ensure that milestones treat each date the same way
					//in the rare case that format is date only for just one date
					format = start.formatString() === 'd' || end.formatString() === 'd'
						? 'd'
						: null;
				}

				if (model.start) {
					model.start = roundDate(model.start, format ?? start.formatString());
					start.value(model.start);
				}

				if (model.end) {
					model.end = roundDate(model.end, format ?? end.formatString());
					end.value(model.end);
				}

				gantt.gantt.dataSource.pushUpdate(model);
			}

			function roundDate(date: Date, format: string): Date {
				if (format === 'd') {
					if (date.getHours() >= 12) {
						date.setHours(24, 0, 0, 0);
					}
					else {
						date.setHours(0, 0, 0, 0);
					}
				}

				return date;
			}

			ko.applyBindingsToDescendants(bindingContext, element);
		}

		init();

		return { controlsDescendantBindings: true };
	}
}
