import $ from 'jquery';
import ko from 'knockout';

ko.bindingHandlers['toggleClass'] = {
	init: function (element, valueAccessor) {
		var value: any = ko.utils.unwrapObservable(valueAccessor());
		$(element).find(value.button).on('click', function (event) {
			$(element).find('legend span').last().toggleClass('fieldset-label-hidden');
			$(element).toggleClass(value.className);
		});
	},
	update: function (element, valueAccessor) {
		var value: any = ko.utils.unwrapObservable(valueAccessor());
		if (value.remove === true) {
			$(element).removeClass(value.className);
		}
	}
}
