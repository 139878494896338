import { loadForm } from '../../../../components/form/load';
import jQuery from 'jquery';

jQuery($ => {
	$(document).on("click", "a.panel-link[target]", e => {
		var a = $(e.currentTarget);
		var url = a.attr("href");
		var target = a.attr("target");

		if (target) {
			var panel = $(`main[data-scriptname='${target}']`);

			if (panel.length) {
				e.preventDefault();
				loadForm(panel, url);
			}
		}
	});
})
