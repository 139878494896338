import { parseClientModel } from '../../util/parse';
import { onLoad } from '../../components/form/load';
import jQuery from 'jquery';

export default async function initialize() {
	await initializePivot();

	onLoad(async (container) => {
		await initializePivot(container);
	})
}

async function initializePivot(container?: JQuery) {
	let pivots = jQuery('.report-pivot', container);

	if (pivots.length > 0) {
		await import(/* webpackChunkName: "kendo-dataviz" */ '../../plugins/kendo/dataviz');

		pivots.each((_, e) => {
			var options: kendo.ui.PivotGridOptions = parseClientModel(jQuery('.pivot-model', e)) as kendo.ui.PivotGridOptions;

			let pivot = jQuery('.pivot-grid', e)
				.kendoPivotGrid(options)
				.data("kendoPivotGrid");

			let config = jQuery('.pivot-config', e)
				.kendoPivotConfigurator({
					dataSource: pivot.dataSource,
					height: options.height
				})
				.data("kendoPivotConfigurator");

			jQuery(window).on("resize", () => {
				pivot.resize();
				config.resize();
			});
		});
	}
}
