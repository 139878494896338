import { PopoverOptions, Trigger } from 'bootstrap';
import 'bootstrap/js/popover';
import $ from 'jquery';
import ko from 'knockout';
import _ from 'lodash';

ko.bindingHandlers['popover'] = {
	update: function (element, valueAccessor) {
		var e = $(element);
		var value: any = ko.utils.unwrapObservable(valueAccessor());
		var show: boolean = null;
		var content = e.attr('data-content') || '';
		var trigger: Trigger = 'hover';
		var html = false;

		if (_.isString(value)) {
			content = value;
		}
		else if (_.isObject(value as any)) {
			content = ko.utils.unwrapObservable<string>(value.content || content);
			trigger = ko.utils.unwrapObservable<string>(value.trigger || trigger) as Trigger;
			var s: any = ko.utils.unwrapObservable(value.show);
			if (_.isBoolean(s)) {
				show = s;
			}
			var htmlContent = ko.utils.unwrapObservable<string>(value.htmlContent);
			if (htmlContent) {
				//jQuery #id selector is not finding dynamic elements here
				var container = document.getElementById(htmlContent);
				if (container) {
					content = $(container).html();
					html = true;
				}
			}
		}
		else {
			show = Boolean(value);
		}

		if (content) {
			e.attr('data-content', content);
			if (!e.data('popover')) {
				let options: PopoverOptions = {
					content: () => e.attr('data-content'),
					placement: 'top',
					trigger: trigger,
					container: 'body',
					html: html
				};

				$(element).popover(options).addClass('has-popover');

				ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
					e.popover('hide');

					window.requestAnimationFrame(() => {
						e.popover('destroy').removeClass('has-popover');
					});
				});
			}
			else {
				if (_.isNull(show)) {
					show = (<any>e.data('popover')).tip().hasClass('in');
				}
			}
		}

		if (e.data('popover') && !_.isNull(show)) {
			if (show && content) {
				e.popover('show');
			}
			else {
				e.popover('hide');
			}
		}
	}
}
