import $ from 'jquery';
import ko from 'knockout';
import _ from 'lodash';
import xmlEncode from '../../../util/xmlEncode';
import { Option, SelectField } from '../model';

ko.bindingHandlers['groupedOptions'] = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel: SelectField) {
		var populate = function (options: Option[]) {
			var $e = $(element);
			$e.empty();

			var selected = {};
			_.each(viewModel.items(), (option: Option) => {
				var id = viewModel.getMapId(option.id);
				if (id) selected[id] = true;
			});

			var addOption = function (option: Option, level: number, index: number, length: number) {
				if (option.children) {
					$("<option/>")
						.prop('disabled', true)
						.addClass('option-group')
						.html(Array(level + 1).join('&nbsp;&nbsp;') + xmlEncode(option.text + ' >'))
						.appendTo($e);

					_.each(option.children, (child, i) => {
						addOption(child, level + 1, i, option.children.length);
					});

					if (index < length - 1 || level > 0) {
						$("<option/>")
							.prop('disabled', true)
							.appendTo($e);
					}
				}
				else {
					$("<option />")
						.prop('value', option.id)
						.prop('selected', selected[option.id] === true)
						.css('margin-left', (level * 10) + 'px')
						.html(Array(level + 1).join('&nbsp;&nbsp;') + xmlEncode(option.text))
						.appendTo($e);
				}
			};

			_.each(options, (o: Option, i) => {
				addOption(o, 0, i, options.length);
			});
		}

		populate(viewModel.options());

		var optionsSubscription =
			viewModel.options.subscribe(function (newValue) {
				populate(newValue);
			});

		var $e = $(element);
		$e.on("change", e => {
			var ids = $e.find('option:selected').map((i, opt) => $(opt).val()).get();
			viewModel.parse(ids);
		});

		ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
			optionsSubscription.dispose();
		});
	},
	update: function (element, valueAccessor, allBindingsAccessor, viewModel: SelectField) {
		var selected = {};
		_.each(viewModel.items(), (option: Option) => {
			var id = viewModel.getMapId(option.id);
			if (id) selected[id] = true;
		});

		$(element).find("option").not(":disabled").each((i, option) => {
			var $o = $(option);
			$o.prop('selected', selected[$o.attr('value')] === true);
		});
	}
}
