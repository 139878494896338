import $ from 'jquery';
import ko from 'knockout';
import _ from 'lodash';
import { settings } from '../../../areas/main/config';
import codeEditor from '../../code-editor';
import { CodeEditorType } from '../../code-editor';
import { ScalarField } from '../model';

ko.bindingHandlers['codeEditor'] = {
	init(element: HTMLInputElement, valueAccessor, allBindingsAccessor, viewModel: ScalarField) {
		const options = ko.unwrap(valueAccessor());
		const type: CodeEditorType = options.type;
		const aliases: string = options.aliases;
		const isInitialValue = type === 'InitialValue';
		const readOnly = !viewModel.editMode || viewModel.isDisabled();

		if (isInitialValue) {
			setInitialValueState(viewModel.editValue());

			viewModel.editValue.subscribe(newValue => {
				setInitialValueState(newValue);
			});
		}
		else {
			$(element).find("input").prop('disabled', true);
		}

		function setInitialValueState(value: string) {
			const disabled = readOnly || (value && value.startsWith("="));

			$(element).find("input").prop('disabled', disabled);
		}

		$(element).find("button").click(e => {
			viewModel.isUpdating(true);

			let value = viewModel.editValue();

			if (isInitialValue && value && value.indexOf("=") === 0) {
				value = value.substring(1);
			}

			let title = viewModel.label;

			if (!readOnly) {
				const prefix = type === 'Sql'
					? settings.strings.editScript
					: settings.strings.editExpression;

				title = `${prefix}: ${title}`;
			}

			codeEditor({
				value: value,
				form: viewModel.form,
				type: type,
				aliases: aliases,
				readOnly: readOnly,
				title: title,
				onShow: () => viewModel.isUpdating(false),
				onAccept: result => {
					if (!_.isString(result)) {
						return;
					}

					result = result.trim();

					if (isInitialValue && result && result.indexOf("=") !== 0) {
						result = "=" + result;
					}

					viewModel.editValue(result);
				}
			});

			e.preventDefault();
		});
	}
}
