import $ from 'jquery';
import ko from 'knockout';
import '../../../plugins/jquery.linkify';
import { ScalarField } from '../model';

ko.bindingHandlers['linkify'] = {
	update: function (element, valueAccessor, allBindingsAccessor, viewModel: ScalarField) {
		valueAccessor();
		$(element).linkify({ target: '_blank' });
	}
}
