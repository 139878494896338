import { settings } from '../config';
import jQuery from 'jquery';
import * as Cookie from '../../../util/cookie';

jQuery(() => {
	jQuery('#top-bar .top-list a').on('click', e => {
		const id = jQuery(e.delegateTarget).data('id') || '';

		Cookie.set(settings.cookieNames.topMenuActiveId, id, {
			path: settings.applicationPath
		});

		//clear activated item on the main menu
		Cookie.remove(settings.cookieNames.menuActiveExpand);
	});

	jQuery('#top-bar .top-list').on('wheel', (e) => {
		e.preventDefault();
		const delta = kendo.wheelDeltaY(e);

		if (delta) {
			e.currentTarget.scrollLeft -= delta;
		}
	});

	jQuery('#top-bar .top-list li.selected')
		.each((_, x) => x.scrollIntoView());
});
