import jQuery from 'jquery';
import ko from 'knockout';

ko.bindingHandlers['updateMask'] = {
	update: function (element, valueAccessor) {
		var updating = ko.utils.unwrapObservable(valueAccessor()) === true;
		var $element = $(element);
		var $mask: JQuery = $element.data('updateMask');

		if (updating) {
			if ($mask) return;

			$mask = jQuery('<div class="update-mask"></div>')
				.appendTo($element)
				.fadeIn(200);

			$element.data('updateMask', $mask);
		}
		else if ($mask) {
			$mask.fadeOut(100, () => $mask.remove());
			$element.data('updateMask', null);
		}
	}
}
