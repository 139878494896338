import $ from 'jquery';
import ko from 'knockout';
import { settings } from '../../../areas/main/config';
import { format, toString } from '../../../util/format';
import { FileField } from '../model';

ko.bindingHandlers['readFileValue'] = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel: FileField) {
		var $e = $(element);
		$e.on('change', e => {
			var supportsFileApi = typeof FileReader !== 'undefined';
			var maxSize = settings.maximumUploadSizeInBytes;
			var files = element.files;

			//check if the file is too big
			if (supportsFileApi && files && files.length == 1) {
				var file = files[0];

				if (file.size > maxSize) {
					viewModel.customError(
						format(
							settings.strings.fileSizeExceeded,
							toString(Math.round(file.size / 1024), ''),
							toString(Math.round(maxSize / 1024), '')
						)
					);
					return;
				}
			}

			viewModel.customError('');
			var val = $e.val();
			valueAccessor()(val);
		});
	}
}
