import $ from 'jquery';
import ko, { BindingContext } from 'knockout';
import OptionPropertyEditor from '../../form/option-editor/OptionPropertyEditor';

ko.bindingHandlers["optionProperty"] = {
	init(element, _, __, ___, bindingContext: BindingContext) {
		$(element).on("change", () => {
			var model: OptionPropertyEditor = bindingContext.columnEditor;
			model.field.value.valueHasMutated();
		});
	}
}
