import Flow from '@flowjs/flow.js';
import ko from 'knockout';

ko.bindingHandlers['file-browse'] = {
	init(element, valueAccessor) {
		//the flow module is being imported for type annotations only, and
		//will be elided in the output. This also means that we do not need to
		//import the module dynamically; this must have happened already because
		//the value of this binding has been constructed using it.
		let flow: Flow = valueAccessor();

		flow.assignBrowse(element, false, false, null);
	}
}
