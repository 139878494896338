import './style.less';
import jQuery from 'jquery';

jQuery(function ($) {
	$("a.error-detail").click(function (e) {
		var $a = $(e.target);
		$a.closest("div").find("div.error-detail").show();
		$a.hide();
		e.preventDefault();
	});
});
