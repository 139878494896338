import $ from 'jquery';

export default function initializeGrid($grid: JQuery) {
	const gridContainer = $grid.find('.grid-scroll-container');

	gridContainer
		.scroll(e => {
			const target = $(e.target);

			target.find('thead').css({
				transform: `translate(0, ${e.target.scrollTop}px)`
			});

			target
				.find('[data-toggle="dropdown"][aria-expanded="true"]')
				.dropdown('toggle');
		})
		.on('click', '.grid-buttons .btn', e => {
			setTimeout(() => {
				//@ts-ignore I must be missing something obvious, but I cannot
				//find a way to either apply the correct type to this handler or
				//successfully assert the type of e.target
				e.target.scrollIntoView(false);
			}, 50);
		});

		initializeGridDropDown(gridContainer);
}

export function offsetFrom(element: JQuery<Element>, parent: JQuery<Element>) {
	const elementOffset = element.offset();
	const parentOffset = parent.offset();

	return ({
		absolute: elementOffset,
		relative: {
			top: elementOffset.top - parentOffset.top,
			left: elementOffset.left - parentOffset.left
		}
	});
}

export function initializeGridDropDown(element: JQuery<Element>) {
	element
		.on('show.bs.dropdown', e => {
			const toggle = $(e.target);
			const dropdown = toggle.find('.dropdown-menu');

			let { absolute, relative } = offsetFrom(toggle, $(e.delegateTarget).closest('.grid-body'));

			const clipTolerance = 30;
			const dropdownHeight = dropdown.outerHeight();

			if (absolute.top + dropdownHeight + clipTolerance > $(window).innerHeight()) {
				//the the window will clip the menu, show it above instead
				relative.top -= dropdownHeight
				dropdown.addClass('above');
			}
			else {
				relative.top += toggle.outerHeight();
				dropdown.removeClass('above');
			}

			dropdown.css(relative);
		});
}