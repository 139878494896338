import './style.less';
import '../../plugins/telerik-report-viewer';
import appUrl from '../../util/appUrl';
import { parseClientModel } from '../../util/parse';
import { onLoad } from '../../components/form/load';
import jQuery from 'jquery';

export default async function initialize() {
	await initializeViewers();

	onLoad(async (container) => {
		await initializeViewers(container);
	})
}

async function initializeViewers(container?: JQuery) {
	const reports = jQuery(".telerik-report", container);

	if (reports.length > 0) {
		await import(/* webpackChunkName: "telerik-report-viewer" */ '../../plugins/telerik-report-viewer');

		reports.each((_, report) => {
			const sourceData = jQuery("script.report-source", report);

			const viewer = jQuery(".report-viewer", report).telerik_ReportViewer({
				serviceUrl: appUrl("~/api/telerik/telerikreport"),
				reportSource: parseClientModel(sourceData),
				scaleMode: 'FIT_PAGE_WIDTH'
			}).data("telerik_ReportViewer");

			const reportContainer = jQuery(report.closest(".report"));

			if (reportContainer.hasClass("with-filter")) {
				jQuery(".toggle-widget", reportContainer).on("click", () => {
					setTimeout(() => viewer.refreshReport(false));
				})
			}
		});
	}
}
